import swal from 'sweetalert';

import {
  signUp,
  signUpPJ,
} from '../../services/AuthService';
import { ErrorHandler } from '../../services/Investor/errorHandler';
import {
  confirmCode,
  setUserPassword,
} from '../../services/Investor/registerService';

export function signupAction(body, navigate, translation, setLoading, email) {
  return (dispatch) => {
    signUp(body)
      .then((response) => {
        localStorage.setItem('useremail', email);
        setLoading(false);
        navigate('/register/email');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message) {
          let errors = [];
          for (const message of error.response.data.message) {
            if (message === 'Email já cadastrado') {
              errors = [...errors, translation('register.errors.api.email').toString()];
            }
            if (message === 'Cpf já está em uso') {
              errors = [...errors, translation('register.errors.api.cpf').toString()];
            }
            if (message.includes('phone')) {
              errors = [...errors, translation('register.errors.api.phone').toString()];
            }
          }
          swal(translation('register.errors.api.title').toString(), errors.toString().replaceAll(',', '\n'), 'error');
        }
      });
  };
}
export function signupActionPJ(body, navigate, translation, setLoading, email) {
  return (dispatch) => {
    signUpPJ(body)
      .then((response) => {
        localStorage.setItem('useremail', email);
        if (response.data.loginLegalUser.status === 'WAITING_KEYCLOAK_REGISTRATION') {
          setLoading(false);
          navigate('/register/email');
        } else {
          setLoading(false);
          navigate('/register/concluded');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          let errors = [];
          if (error.response?.data) {
            for (const message of error.response.data.message) {
              if (message === 'Cpf já está em uso') {
                errors = [...errors, translation('register.errors.api.cpf').toString()];
              }
              if (message === 'Cnpj já cadastrado') {
                errors = [...errors, translation('register.errors.api.cnpj').toString()];
              }
              if (message.includes('phone')) {
                errors = [...errors, translation('register.errors.api.phone').toString()];
              }
            }
            swal(translation('register.errors.api.title').toString(), errors.toString().replaceAll(',', '\n'), 'error');
          }
        }
      });
  };
}

export function verifyEmail(params, navigate, translation, setLoading) {
  return async (dispatch) => {
    await confirmCode(params)
      .then((response) => {
        // dispatch();
        setLoading(false);
        localStorage.setItem('access_token_register', params.token);
        navigate('/register/password');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errorCode = ErrorHandler(error.response.data.message);
          const errorMsg = translation(errorCode).toString();
          swal('Error', errorMsg, 'error');
        }
      });
  };
}

export function singupPassword(body, navigate, translation, setLoading) {
  return async (dispatch) => {
    await setUserPassword(body)
      .then((response) => {
        setLoading(false);
        localStorage.removeItem('useremail');
        localStorage.clear();
        navigate('/login');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errorCode = ErrorHandler(error.response.data.message);
          const errorMsg = translation(errorCode).toString();
          swal('Error', errorMsg, 'error');
        }
      });
  };
}
