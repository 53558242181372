import {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { stage2 } from '@assets';
import {
  SelectInput,
  Translator,
} from '@components';
import { useProfile } from '@contexts-investor';
import {
  cnpjMask,
  cpfMask,
  moneyMask,
  validadeCpf,
} from '@utils';

const SecondStep = ({ props: { handleStep } }) => {
  const { t } = useTranslation();
  const { profile } = useProfile();
  const [selectedOption, setSelectedOption] = useState('');
  const [amount, setAmount] = useState(0);
  const [value, setValue] = useState(0);
  const [key, setKey] = useState();
  const errorsObj = { obj: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [userCpf, setUserCpf] = useState()

  useEffect(() => {
    if (profile) {
      if (profile?.physicalUser) {
        setKey(cpfMask(profile?.physicalUser.cpf))
        setSelectedOption("cpf")
      }
      if (profile?.legalUser) {
        setKey(cnpjMask(profile.legalUser.cnpj))
        setSelectedOption("cnpj")
      }
    }
  }, [profile]);

  const selectOption = [
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
  ];

  const selectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // background: 'rgba(247, 219, 179, 0.3)',
      borderRadius: '0.5rem',
      height: '3rem',
      border: '1px solid #C0C0C0',
      color: 'black',
      fontColor: 'red',
      outlineColor: '#ff9900',
      boxShadow: 'none',
      '&:focus': {
        border: '#ff9900',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FF9900' : 'white',
      color: 'var(--darktext)',
      '&:hover': {
        backgroundColor: '#FF9900',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: 'var(--darktext)',
    }),
  };

  const selectData = {
    options: selectOption,
    styles: selectStyles,
    select: selectedOption,
    setSelect: setSelectedOption,
    isDisabled: true,
    placeholder: selectedOption.toUpperCase(),
  };

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const isPhone = (phone) =>
    /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);
  //br xx-xxxxx-xxxx  || br xx-xxxx-xxxx || us xxx-xxx-xxxx

  const handleWage = (e) => {
    const wage = moneyMask(e.target.value);
    setAmount(wage);
    const val = e.target.value
      .replace('R', '')
      .replace('$', '')
      .replaceAll('.', '')
      .replaceAll(',', '')
      .replaceAll(' ', '');
    setValue(val);
  };

  const handleWageMax = (e, max) => {
    e.target.value = max;
    const wage = moneyMask(e.target.value);
    setAmount(wage);
    const val = e.target.value
      .replace('R', '')
      .replace('$', '')
      .replaceAll('.', '')
      .replaceAll(',', '')
      .replaceAll(' ', '');
    setValue(val);
  };

  const handleClick = () => {
    if (profile) {
      if (value === undefined) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.valError').toString();
        setErrors(errorObj);
      } else if (selectedOption === null) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.typeError').toString();
        setErrors(errorObj);
      } else if (key === undefined) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.keyError').toString();
        setErrors(errorObj);
      } else if (selectedOption.value === 'email' && !isEmail(key)) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.emailError').toString();
        setErrors(errorObj);
      } else if (selectedOption.value === 'phone' && !isPhone(key)) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.phoneError').toString();
        setErrors(errorObj);
      } else if (selectedOption.value === 'cpf' && !validadeCpf(key)) {
        const errorObj = { ...errorsObj };
        errorObj.obj = t('withdrawPage.secondStep.cpfError').toString();
        setErrors(errorObj);
      } else {
        const info = {
          userId: profile.userId,
          keyType: selectedOption,
          value: (value / 100).toString(),
          key,
        };
        handleStep(info);
      }
    }
  };

  const handleKey = (value) => {
    if (selectedOption.value === 'cpf') {
      const cpfFormated = cpfMask(value);
      setKey(cpfFormated);
    } else {
      setKey(value);
    }
  };

  return (
    <>
      <h3>
        <Translator path="withdrawPage.secondStep.title" />
      </h3>
      <img className="col-8" src={stage2} />
      <div className="row col-9 d-flex flex-column justify-content-center align-items-center step-two">
        <div className='warning'>
          <i className="material-icons">error</i>
          <label style={{ color: '#525252', marginTop: '0.5rem', marginLeft: '0.5rem' }}>
            <Translator path="withdrawPage.secondStep.warning" />
          </label>
        </div>
        <p className="step-two">
          <Translator path="withdrawPage.secondStep.val" />
        </p>
        <div className='value-quantity'>
          <input className="step-two col-9" value={amount != 0 ? amount : 'R$ 0,00'} onChange={(e) => handleWage(e)} />
          <button
            className="btn btn-orange"
            style={{
              padding: '0',
              width: '4rem',
              height: '1.5rem',
              fontSize: '0.70rem',
            }}
            onClick={(e) => handleWageMax(e, profile?.wallet?.balance)}
          >
            <Translator path="withdrawPage.secondStep.max" />
          </button>
        </div>


        <p className="step-two">
          <Translator path="withdrawPage.secondStep.type" />
        </p>
        <div className="col-12">
          <SelectInput props={selectData} />
        </div>

        <p className="step-two">
          <Translator path="withdrawPage.secondStep.key" />
        </p>
        <input className="step-two col-11" value={key} disabled={true} style={{ backgroundColor: '#F0F0F0', border: '1px solid #C0C0C0' }} />
      </div>
      {errors.obj && <div className="text-danger fs-12">{errors.obj}</div>}
      <button className=" col-6 btn btn-orange" onClick={() => handleClick()}>
        <Translator path="withdrawPage.secondStep.submit" />
      </button>
    </>
  );
};

export default SecondStep;
